import { Box, Flex, Spacer, Text } from "@chakra-ui/layout"
import { Textarea, Input, Button } from "@chakra-ui/react"
import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/Layout"
import axios from "axios"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Contact = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [token, setToken] = useState("")
  const { executeRecaptcha } = useGoogleReCaptcha()
  const func = {
    setFirstName,
    setLastName,
    setEmail,
    setMessage,
  }

  const handleChange = e => {
    func[e.target.name](e.target.value)
  }

  useEffect(() => {
    sendMail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const sendMail = async () => {
    if (token !== "" && token) {
      try {
        await axios({
          method: "post",
          url: "https://pushkproduction.com/contact",
          timeout: 10000,
          data: {
            email,
            message,
            token,
            firstName,
            lastName,
          },
        })
        // Success 🎉
        alert("Votre message a bien été envoyée ! ✅")
      } catch (error) {
        // Error 😨
        if (error.response) {
          alert("Une erreur est survenue 😨 Ressayez plus tard... ⏳")
        } else if (error.request) {
          alert("Une erreur est survenue 😨 Ressayez plus tard... ⏳")
        } else {
          alert("Une erreur est survenue 😨 Ressayez plus tard... ⏳")
        }
        console.log(error)
      }
    }
  }

  const submitMail = async e => {
    e.preventDefault()
    if (email === "" || message === "" || firstName === "" || lastName === "") {
      alert("Merci de renseigner tout les champs.")
      return
    }
    if (!executeRecaptcha) {
      return
    }
    const result = await executeRecaptcha("contact")
    setToken(result)
  }

  return (
    <Layout>
      <Box
        textAlign="center"
        fontSize={16}
        color="white"
        my={["sm", "sm", "md", "md"]}
        maxW={["90%", "50%", "40%", "40%"]}
        m="0 auto"
      >
        <Text fontWeight={800} my={["sm", "sm", "md", "md"]}>
          For all mixing / mastering requests or questions relating to this area
          :
        </Text>
        <form
          onSubmit={submitMail}
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          <Flex align="center" justify="center" mt="sm">
            <Box mr="sm">
              <Text
                m="0 auto"
                textAlign="center"
                fontWeight={800}
                fontSize={15}
                mb="xs"
                textTransform="uppercase"
              >
                First name
              </Text>
              <Input
                borderColor="#FFFFFF"
                borderWidth={1}
                onChange={handleChange}
                fontSize={17}
                type="text"
                value={firstName}
                name="setFirstName"
                required
                m="0 auto"
              />
            </Box>
            <Spacer />
            <Box ml="sm">
              <Text
                m="0 auto"
                textAlign="center"
                fontWeight={800}
                fontSize={15}
                mb="xs"
                textTransform="uppercase"
              >
                Last name
              </Text>
              <Input
                borderColor="#FFFFFF"
                borderWidth={1}
                onChange={handleChange}
                fontSize={17}
                type="text"
                value={lastName}
                name="setLastName"
                required
                m="0 auto"
              />
            </Box>
          </Flex>
          <Text
            m="0 auto"
            textAlign="center"
            fontWeight={800}
            fontSize={15}
            mt="md"
            mb="xs"
          >
            EMAIL
          </Text>
          <Input
            borderColor="#FFFFFF"
            borderWidth={1}
            onChange={handleChange}
            fontSize={17}
            type="email"
            value={email}
            name="setEmail"
            required
            m="0 auto"
          />
          <Text
            m="0 auto"
            textAlign="center"
            fontWeight={800}
            fontSize={15}
            mt="md"
            mb="xs"
          >
            MESSAGE
          </Text>
          <Textarea
            type="textarea"
            name="setMessage"
            fontSize={17}
            onChange={handleChange}
            required
            value={message}
            borderColor="#FFFFFF"
            borderWidth={1}
          />
          <Button
            type="submit"
            display="block"
            fontSize={15}
            fontWeight={800}
            m="0 auto"
            mt="md"
            size="md"
            color="black"
            borderRadius={5}
            _hover={{
              bgColor: "#111111",
              color: "white",
            }}
          >
            SEND
          </Button>
        </form>
      </Box>
    </Layout>
  )
}

export default Contact
